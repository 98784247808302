import { Route, Routes } from "react-router-dom";
import Authenticate from "./components/Authenticate";
import { extendTheme, ChakraBaseProvider } from "@chakra-ui/react";
import Login from "./screens/Login";
import ActivateUser from "./screens/ActivateUser";
import ResetPassword from "./screens/ResetPassword";
import ForgotPassword from "./screens/ForgotPassword";
import FormModal from "./components/modals/FormModal";
import ConfirmModal from "./components/modals/ConfirmModal";
import Dashboard from "./screens/Dashboard";
import EventManagement from "./screens/EventManagement";
import UserMgtUsers from "./screens/UserMgtUsers";
import UserMgtRoles from "./screens/UserMgtRoles";
// import ApprovalSetup from "./screens/ApprovalSetup";
import ApprovalReport from "./screens/ApprovalReport";
import MemberReport from "./screens/MemberReport";
import ExamCenterReport from "./screens/ExamCenterReport";
import InductionReport from "./screens/InductionReport";
import EMembershipCardReport from "./screens/EMembershipCardReport";
import BirthdayReport from "./screens/BirthdayReport";
import ExamDiet from "./screens/ExamDiet";
import ExamCategories from "./screens/ExamCategories";
import ExamSubjects from "./screens/ExamSubjects";
import ExamCenters from "./screens/ExamCenters";
import ExamTimetable from "./screens/ExamTimetable";
import ExamResult from "./screens/ExamResult";
import Announcement from "./screens/Announcement";
import Profile from "./screens/Profile";
import Email from "./screens/Email";
import EventDetails from "./components/EventDetails";
import UserMgtProfile from "./components/UserMgtProfile";
import RegisteredStudents from "./screens/RegisteredStudents";
import ExamPeriod from "./components/ExamPeriod";
import ProfileChangePassword from "./screens/ProfileChangePassword";
import FeeType from "./screens/FeeType";
import FeeItems from "./screens/FeeItems";
import ApprovalSettings from "./screens/ApprovalSettings";
import ApprovalWorkflow from "./screens/ApprovalWorkflow";
import DepartmentManagement from "./screens/DepartmentManagement";
import EventReport from "./screens/EventReport";
import ExamApplicantReport from "./screens/ExamApplicantReport";
import ExamListingReport from "./screens/ExamListingReport";
import MemberReportProfile from "./components/memberReportProfile";
import StudentReport from "./screens/StudentReport";
import MemberDuesMGT from "./screens/MemberDuesMGT";
import UpdateBiodata from "./screens/UpdateBiodata";
// import MembershipUpgrade from "./screens/MembershipUpgrade";
import UpdateBiodataChange from "./screens/UpdateBiodataChange";
import Student from "./screens/Student";
import NYSC from "./screens/NYSC";
import Associate from "./screens/Associate";
import Fellow from "./screens/Fellow";
import StatementOfResult from "./screens/StatementOfResult";
import ResultStatement from "./screens/ResultStatement";
import EducationExamResult from "./screens/EducationExamResult";
import EduExamResultDetails from "./screens/EduExamResultDetails";
import StudentApplicationForm from "./screens/StudentApplicationForm";
import MemberManagementReport from "./screens/MemberManagementReport";
import CustomToast from "./components/layout/CustomToast";
import NYSCApplicationForm from "./screens/NYSCApplicationForm";
import ProgrammeManagement from "./screens/ProgrammeManagement";
import MyDues from "./screens/MyDues";
import Report from "./screens/Report";
import PaymentTypeReport from "./screens/PaymentTypeReport";
import TransactionLogReport from "./screens/TransactionLogReport";
import MemberOutstandingDuesReport from "./screens/MemberOutstandingDuesReport";
import DuesPaymentDetails from "./components/DuesPaymentDetails";
import ExamApplication from "./screens/ExamApplication";
import ChangeExamCentre from "./screens/ChangeExamCentre";
import PrintExamDocket from "./screens/PrintExamDocket";
import ViewExamResult from "./screens/ViewExamResult";
import MemberModuleReport from "./screens/MemberModuleReport";
import ExamAppCenter from "./components/ExamAppCentre";
import ExamPhotoCard from "./components/ExamPhotoCard";
import MemberExamStatement from "./components/MemberExamStatement";
import PrintAdmissionLetter from "./screens/PrintAdmissionLetter";
import StudentDashboard from "./screens/StudentDashboard";
import StudentRegScreen from "./screens/StudentRegScreen";
import StudentConfirmAccount from "./screens/StudentConfirmAccount";
import NYSCRegScreen from "./screens/NYSCRegScreen";
import MemberUpgrade from "./screens/MemberUpgrade";
import AdmissionLetter from "./screens/AdmissionLetter";
import MembershipUpgrade from "./screens/MembershipUpgrade";
import EventMgtMemberModule from "./screens/EventMgtMemberModule";
import MembershipUpgradeReport from "./components/MembershipUpgradeReport";
import EntryQualificationType from "./screens/EntryQualificationType";
import ApplicableExemption from "./screens/ApplicableExemption";
import ExamDetails from "./components/ExamDetails";
import NewExamCentreChange from "./screens/NewExamCentreChange";
import ExamCentreChangeApplication from "./screens/ExamCentreChangeApplication";
import StudentApplicationFormNew from "./screens/StudentApplicationFormNew";
import NYSCApplicationFormNew from "./screens/NYSCApplicationFormNew";
import NotFound from "./screens/NotFound";


const theme = extendTheme({
  fonts: {
    heading: `'DaxlinePro', sans-serif`,
    body: `'DaxlinePro', sans-serif`,
    roboto: `'Roboto', sans-serif`,
    inter: ` 'Inter', sans-serif`,
  },
  colors: {
    brand: {
      primary: "rgba(30, 30, 30, 0.5)",
      table: "#F9F8F8",
    },
  },
  Table: {
    variants: {
      striped: {
        tbody: {
          "tr:nth-child(odd)": {
            bg: "#F9F8F8",
          },
        },
      },
    },
  },
});

const App = () => {
  return (
    <ChakraBaseProvider theme={theme}>
      <FormModal />
      <ConfirmModal />
      <CustomToast />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/activate-account/:userId" element={<ActivateUser />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/student-application-form" element={<StudentApplicationForm />} />
        <Route path="/student-application-form-new" element={<StudentApplicationFormNew />} />
        <Route path="/nysc-application-form" element={<NYSCApplicationForm />} />
        <Route path="/nysc-application-form-new" element={<NYSCApplicationFormNew />} />
        <Route path="/student-registration" element={<StudentRegScreen />} />
        <Route path="/confirm-account" element={<StudentConfirmAccount />} />
        <Route path="/nysc-registration" element={<NYSCRegScreen />} />

        <Route element={<Authenticate />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/studentdashboard" element={<StudentDashboard />} />
          <Route path="/eventmanagement/events" element={<EventManagement />} />
          <Route path="/event-details" element={<EventDetails />} />
          <Route path="/usermanagement/users" element={<UserMgtUsers />} />
          <Route path="/usermanagement/roles" element={<UserMgtRoles />} />
          <Route
            path="/usermanagement/user-profile"
            element={<UserMgtProfile />}
          />

          <Route path="/feemanagement/feetype" element={<FeeType />} />
          <Route path="/feemanagement/fee" element={<FeeItems />} />
          <Route
            path="/Approval/approvalsettings"
            element={<ApprovalSettings />}
          />
          <Route
            path="/Approval/approvalworkflow"
            element={<ApprovalWorkflow />}
          />
          <Route
            path="/departmentmanagement/departmentmanagement"
            element={<DepartmentManagement />}
          />
          {/* <Route path="/Approval/approvalsetup" element={<ApprovalSetup />} /> */}
          <Route path="/report/approvalreport" element={<ApprovalReport />} />
          <Route path="/report/studentreport" element={<StudentReport />} />
          <Route path="/report/memberreport" element={<MemberReport />} />
          <Route path="/report/membermanagementreport" element={<MemberManagementReport />} />
          <Route
            path="/report/examcentrereport"
            element={<ExamCenterReport />}
          />
          <Route path="/registered-students" element={<RegisteredStudents />} />
          <Route path="/report/inductionreport" element={<InductionReport />} />
          <Route
            path="/report/emembershipcardreport"
            element={<EMembershipCardReport />}
          />
          <Route path="/report/memberupgradereport" element={<MembershipUpgradeReport />} />
          <Route path="/report/birthdayreport" element={<BirthdayReport />} />
          <Route path="/examsetup/examdiet" element={<ExamDiet />} />
          <Route
            path="/examsetup/examdiet/exam-period"
            element={<ExamPeriod />}
          />
          <Route
            path="/examsetup/examcategories"
            element={<ExamCategories />}
          />
          <Route
            path="/examsetup/examcategories/exam-subjects"
            element={<ExamSubjects />}
          />
          <Route path="/examsetup/examcentres" element={<ExamCenters />} />
          <Route path="/examsetup/examtimetable" element={<ExamTimetable />} />
          <Route path="/examsetup/examresult" element={<ExamResult />} />
          <Route path="/examsetup/educationexamresult" element={<EducationExamResult />} />
          <Route path="/examsetup/educationexamresult/details" element={<EduExamResultDetails />} />
          <Route
            path="/report/memberreport/member-profile"
            element={<MemberReportProfile />}
          />
          <Route path="/report/eventreport" element={<EventReport />} />
          <Route
            path="/report/examapplicantreport"
            element={<ExamApplicantReport />}
          />
          <Route
            path="/report/examresultlistingreport"
            element={<ExamListingReport />}
          />
          <Route path="/programmemanagement" element={<ProgrammeManagement />} />
          <Route path="/report" element={<Report />} />
          <Route path="/paymentreport" element={<PaymentTypeReport />} />
          <Route path="/transactionlogreport" element={<TransactionLogReport />} />
          <Route path="/membershipoutstandingduesreport" element={<MemberOutstandingDuesReport />} />
          <Route path="/report/memberduesmanagement" element={<MemberDuesMGT />} />
          <Route path="/membermanagement/updatebiodata" element={<UpdateBiodata />} />
          <Route path="/membermanagement/memberupgrade" element={<MembershipUpgrade />} />
          <Route path="/membermanagement/biodatachangeapproval" element={<UpdateBiodataChange />} />
          <Route path="/applicationapproval/studentapplication" element={<Student />} />
          <Route path="/applicationapproval/nyscapplication" element={<NYSC />} />
          <Route path="/applicationapproval/centrechangeapplication" element={<ExamCentreChangeApplication />} />
          <Route path="/applicationapproval/associate" element={<Associate />} />
          <Route path="/applicationapproval/fellow" element={<Fellow />} />
          <Route path="/statementofresult/result" element={<StatementOfResult />} />
          <Route path="/statementofresult/resultStatement" element={<ResultStatement />} />
          <Route path="/Announcement" element={<Announcement />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/mydues" element={<MyDues />} />
          <Route path="/mydues/paymentdetails" element={<DuesPaymentDetails />} />
          <Route path="/exammanagement/examapplication" element={<ExamApplication />} />
          <Route path="/exammanagement/changeexamcentre" element={<ChangeExamCentre />} />
          <Route path="/exammanagement/newexamcentrechange" element={<NewExamCentreChange />} />
          <Route path="/exammanagement/printexamdocket" element={<PrintExamDocket />} />
          <Route path="/exammanagement/printexamdocket/examphotocard" element={<ExamPhotoCard />} />
          <Route path="/exammanagement/viewexamresult" element={<ViewExamResult />} />
          <Route path="/exammanagement/viewexamresult/memberexamstatement" element={<MemberExamStatement />} />
          <Route path="/report/memberpaymenthistoryreport" element={<MemberModuleReport />} />
          <Route path="/exammanagement/examappcentre" element={<ExamAppCenter />} />
          <Route path="/printadmissionletter" element={<PrintAdmissionLetter />} />

          <Route path="/memberupgrade" element={<MemberUpgrade />} />
          <Route path="/admissionletter" element={<AdmissionLetter />} />
          <Route path="/examsetup/entryqualificationtype" element={<EntryQualificationType />} />
          <Route path="/examsetup/entryqualificationtype/applicableexemption" element={<ApplicableExemption />} />
          <Route path="/memberprofile-events" element={<EventMgtMemberModule />} />

          <Route path="entryqualification" element={<EntryQualificationType />} />
          <Route path="entryqualification/applicableexemption" element={<ApplicableExemption />} />

          <Route path="/examapplications/view-exam-details" element={<ExamDetails />} />
          <Route
            path="/profile/change-password"
            element={<ProfileChangePassword />}
          />
          <Route path="/Email" element={<Email />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </ChakraBaseProvider>
  );
};

export default App;
